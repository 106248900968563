import { ServicesData } from 'types/content/services';
import { CaseStudiesData } from 'types/content/caseStudies';
import { ContactData } from '../types/content/contact';
import { CompanyData } from '../types/content/company';
import { JoinData } from '../types/content/join';
import { HomeData } from '../types/content/home';
import { CookieData } from '../types/content/cookies';
import cookieYaml from './cookies.yaml';
import { PrivacyData } from '../types/content/privacy';
import { TermsData } from '../types/content/termsofuse';
import { PortfolioData } from '../types/content/portfolio';
import { CareersData } from '../types/content/careers';
import { CareerDetailData } from '../types/content/careerDetail';

import adobeYaml from './casestudy/adobe.yaml';
import homeYaml from './home.yaml';
import joinYaml from './join.yaml';
import foxCaseStudyYaml from './casestudy/fox.yaml';
import contactYaml from './contact.yaml';
import companyYaml from './company.yaml';
import PrivacyYaml from './privacy.yaml';
import termsYaml from './termsofuse.yaml';
import portfolioYaml from './portfolio.yaml';
import porscheYaml from './casestudy/porsche.yaml';
import servicesYaml from './services.yaml';
import careersYaml from './careers/index.yaml';
import careerDetail from './careers/detail/careerDetail.yaml';

export const adobeContent: CaseStudiesData = adobeYaml;

export const homeContent: HomeData = homeYaml;

export const joinContent: JoinData = joinYaml;

export const foxContent: CaseStudiesData = foxCaseStudyYaml;

export const contactContent: ContactData = contactYaml;

export const companyContent: CompanyData = companyYaml;

export const cookieContent: CookieData = cookieYaml;
export const privacyContent: PrivacyData = PrivacyYaml;
export const termsContent: TermsData = termsYaml;
export const portfolioContent: PortfolioData = portfolioYaml;
export const porscheContent: CaseStudiesData = porscheYaml;

export const servicesContent: ServicesData = servicesYaml;
export const careersContent: CareersData = careersYaml;
export const careerDetailContent: CareerDetailData = careerDetail;
